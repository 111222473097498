"use client";
import * as z from 'zod';  
import { useEffect, useState } from 'react'; 
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { Input } from '@/components/ui/input';
import { signIn, useSession } from 'next-auth/react';
import { useRouter } from "next/navigation";
import { FaFacebook, FaGoogle, FaApple } from "react-icons/fa";

const FormSchema = z.object({
  email: z.string().min(1, 'Email is required').email('Invalid email'),
  password: z
    .string()
    .min(1, 'Password is required')
    .min(8, 'Password must have at least 8 characters'),
});

export default function Login() {
  const { data: session } = useSession();
  const router = useRouter();
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false); // Add loading state
  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const onSubmit = async (values: z.infer<typeof FormSchema>) => {
    setLoading(true); // Start loading
    const signInData = await signIn('credentials', {
      redirect: false,
      email: values.email,
      password: values.password,
    });

    if (signInData?.error) {
      setError('Password or username incorrect');
    } else {
      setError(null);
      router.push('/');
    }
    setLoading(false); // End loading
  };

  useEffect(()=> {
    if (session) {
        router.push('/');
        router.refresh();
      }
  },[session])
  

  return (
    <div className="max-w-[1400px] flex m-auto justify-center text-white min-h-screen -mt-32">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="w-[600px] justify-center my-auto lg:px-8 pb-4 sm:pb-6 xl:pb-8">
          <h3 className="text-3xl font-medium my-8">Sign in</h3>

          <div>
            <p>If you don't have an account</p>
            <p>You can <a href="/register" className="text-company-green hover:underline">Register here !</a></p>
          </div>

          <FormField
            control={form.control}
            name='email'
            render={({ field }) => (
              <FormItem className="my-10">
                <FormLabel className="text-sm font-medium text-gray-900 block mb-2">E-mail</FormLabel>
                <FormControl>
                  <Input className="border border-white text-gray-900 sm:text-sm rounded-lg focus-visible:ring-transparent focus-visible:border-company-green focus-visible:ring-offset-0 block w-full px-2.5 bg-black" placeholder='mail@example.com' {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name='password'
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-sm font-medium text-gray-900 block mb-2">Your Password</FormLabel>
                <FormControl>
                  <Input type="password" className="border border-white text-gray-900 sm:text-sm rounded-lg focus-visible:ring-transparent focus-visible:border-company-green focus-visible:ring-offset-0 focus:outline-none focus:ring-0 block w-full p-2.5 bg-black" placeholder='••••••••' {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <div className="flex justify-end">
            <a href="/login/forgotPassword" className="text-xs mt-4 hover:underline">Forgot Password?</a>
          </div>

          {error && (
            <div className=" text-error text-sm p-3 rounded-md mt-8 bg-error bg-opacity-5 border border-error">
              <p className='text-xs'>{error}</p>
            </div>
          )}

          <Button type="submit" className={`w-full text-company-gray bg-company-green focus-visible:ring-transparent focus-visible:ring-offset-0 focus:ring-0 font-medium rounded-lg text-sm px-5 py-2.5 mt-4 text-center ${loading ? 'cursor-not-allowed opacity-50' : ''}`} disabled={loading}>
            {loading ? 'Loading...' : 'Login'}
          </Button>

          <div className="justify-center my-10 m-auto flex">
            <p className="opacity-80">or continue with</p>
          </div>

          <div className="flex justify-center m-auto gap-6">
            <FaGoogle className="text-4xl text-white hover:text-company-green cursor-pointer" />
            <FaFacebook className="text-4xl text-white hover:text-company-green cursor-pointer" />
            <FaApple className="text-4xl text-white hover:text-company-green cursor-pointer" />
          </div>
        </form>
      </Form>
    </div>
  );
}
